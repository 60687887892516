import React from 'react';

const icon = (props) => {
  return (
  <g fill={ props.fill } transform="translate(36.8 46.4)">
    <path d="M88.32,0 C79.04,0 70.72,7.68 64,18.4 C58.4,9.44 50.08,0.32 39.68,0.32 C17.12,0 0,44.32 0,66.4 C0,88.48 17.92,106.08 39.68,106.08 C48.48,106.08 57.28,103.2 64,97.6 C70.72,102.88 79.2,106.08 88.32,106.08 C110.08,106.24 128,88.32 128,66.4 C128,44.48 110.88,0 88.32,0 Z M5.28,66.4 C5.28,46.88 21.6,5.28 39.68,5.28 C46.56,5.28 54.4,11.68 60.96,23.36 C53.28,37.44 48.64,54.88 48.64,66.24 C48.64,76.96 52.96,86.72 59.84,93.76 C53.92,98.08 46.72,100.48 39.52,100.48 C20.8,100.8 5.28,85.44 5.28,66.4 L5.28,66.4 Z M88.32,100.8 C69.44,100.8 53.92,85.28 53.92,66.4 C53.92,47.52 70.24,5.28 88.32,5.28 C106.4,5.28 122.72,46.88 122.72,66.4 C122.72,85.92 107.2,100.8 88.32,100.8 Z"/><path d="M114.72,63.84 C113.12,63.84 112,64.96 112,66.56 C112,79.68 101.28,90.24 88.32,90.24 C86.72,90.24 85.6,91.36 85.6,92.96 C85.6,94.56 86.72,95.68 88.32,95.68 C104.32,95.68 117.44,82.56 117.44,66.56 C117.28,65.12 116.32,63.84 114.72,63.84 Z"/>
  </g>
  )
};

export default icon;
