import { lazy } from 'react'

export default {
  'flavor_citrus': {
    id:1,
    name: 'citrus',
    lokalise_slug: 'citrus',
    path: lazy(() => import('./citrus')),
    viewBox: '200 200',
  },
  'flavor_vanilla': {
    id:2,
    name: 'vanilla',
    lokalise_slug: 'vanilla',
    path: lazy(() => import('./vanilla')),
    viewBox: '200 200',
  },
  'flavor_butter': {
    id:3,
    name: 'butter',
    lokalise_slug: 'butter',
    path: lazy(() => import('./butter')),
    viewBox: '200 200',
  },
  'flavor_apple': {
    id:4,
    name: 'apple',
    lokalise_slug: 'apple',
    path: lazy(() => import('./apple')),
    viewBox: '200 200',
  },
  'flavor_floral': {
    id:5,
    name: 'floral',
    lokalise_slug: 'floral',
    path: lazy(() => import('./floral')),
    viewBox: '200 200',
  },
  'flavor_herbal': {
    id:6,
    name: 'herbal',
    lokalise_slug: 'herbal',
    path: lazy(() => import('./herbal')),
    viewBox: '200 200',
  },
  'flavor_baking_spice': {
    id:7,
    name: 'baking spice',
    lokalise_slug: 'baking_spice',
    path: lazy(() => import('./baking_spice')),
    viewBox: '200 200',
  },
  'flavor_tropical': {
    id:8,
    name: 'tropical',
    lokalise_slug: 'tropical',
    path: lazy(() => import('./tropical')),
    viewBox: '200 200',
  },
  'flavor_peach': {
    id:9,
    name: 'peach',
    lokalise_slug: 'peach',
    path: lazy(() => import('./peach')),
    viewBox: '200 200',
  },
  'flavor_earthy': {
    id:10,
    name: 'earthy',
    lokalise_slug: 'earthy',
    path: lazy(() => import('./earthy')),
    viewBox: '200 200',
  },
  'flavor_toast': {
    id:11,
    name: 'toast',
    lokalise_slug: 'toast',
    path: lazy(() => import('./toast')),
    viewBox: '200 200',
  },
  'flavor_berry': {
    id:12,
    name: 'berry',
    lokalise_slug: 'berry',
    path: lazy(() => import('./berry')),
    viewBox: '200 200',
  },
  'flavor_cherry': {
    id:13,
    name: 'cherry',
    lokalise_slug: 'cherry',
    path: lazy(() => import('./cherry')),
    viewBox: '200 200',
  },
  'flavor_jammy': {
    id:14,
    name: 'jammy',
    lokalise_slug: 'jammy',
    path: lazy(() => import('./jammy')),
    viewBox: '200 200',
  },
  'flavor_chocolate': {
    id:15,
    name: 'chocolate',
    lokalise_slug: 'chocolate',
    path: lazy(() => import('./chocolate')),
    viewBox: '200 200',
  },
  'flavor_wood': {
    id:16,
    name: 'wood',
    lokalise_slug: 'wood',
    path: lazy(() => import('./wood')),
    viewBox: '200 200',
  },
  'flavor_smoke': {
    id:17,
    name: 'smoke',
    lokalise_slug: 'smoke',
    path: lazy(() => import('./smoke')),
    viewBox: '200 200',
  },
  'flavor_peat': {
    id:18,
    name: 'peat',
    lokalise_slug: 'peat',
    path: lazy(() => import('./peat')),
    viewBox: '200 200',
  },
  'flavor_caramel': {
    id:19,
    name: 'caramel',
    lokalise_slug: 'caramel',
    path: lazy(() => import('./caramel')),
    viewBox: '200 200',
  },
  'flavor_agave': {
    id:20,
    name: 'agave',
    lokalise_slug: 'agave',
    path: lazy(() => import('./agave')),
    viewBox: '200 200',
  },
  'flavor_grain': {
    id:21,
    name: 'grain',
    lokalise_slug: 'grain',
    path: lazy(() => import('./grain')),
    viewBox: '200 200',
  },
  'flavor_hops': {
    id:22,
    name: 'hops',
    lokalise_slug: 'hops',
    path: lazy(() => import('./hops')),
    viewBox: '200 200',
  },
  'flavor_malt': {
    id:23,
    name: 'malt',
    lokalise_slug: 'malt',
    path: lazy(() => import('./malt')),
    viewBox: '200 200',
  },
  'flavor_nutty': {
    id:24,
    name: 'nutty',
    lokalise_slug: 'nutty',
    path: lazy(() => import('./nutty')),
    viewBox: '200 200',
  },
  'flavor_dough': {
    id:25,
    name: 'dough',
    lokalise_slug: 'dough',
    path: lazy(() => import('./dough')),
    viewBox: '200 200',
  },
  'flavor_vegetal': {
    id:26,
    name: 'vegetal',
    lokalise_slug: 'vegetal',
    path: lazy(() => import('./vegetal')),
    viewBox: '200 200',
  },
  'flavor_plum': {
    id:27,
    name: 'plum',
    lokalise_slug: 'plum',
    path: lazy(() => import('./plum')),
    viewBox: '200 200',
  },
  'flavor_mineral': {
    id:28,
    name: 'mineral',
    lokalise_slug: 'mineral',
    path: lazy(() => import('./mineral')),
    viewBox: '200 200',
  },
  'flavor_candy': {
    id:29,
    name: 'candy',
    lokalise_slug: 'candy',
    path: lazy(() => import('./candy')),
    viewBox: '200 200',
  },
  'flavor_coffee': {
    id:30,
    name: 'coffee',
    lokalise_slug: 'coffee',
    path: lazy(() => import('./coffee')),
    viewBox: '200 200',
  },
  'flavor_spicy': {
    id:31,
    name: 'spicy',
    lokalise_slug: 'spicy',
    path: lazy(() => import('./spicy')),
    viewBox: '200 200',
  },
  'flavor_dairy': {
    id:32,
    name: 'dairy',
    lokalise_slug: 'dairy',
    path: lazy(() => import('./dairy')),
    viewBox: '200 200',
  },
}
