import React, { Suspense, lazy, useState, useEffect } from 'react'
import clsx from 'clsx';
import PropTypes from 'prop-types';
import paths from './flavor-paths';
import LokaliseText from 'GLOBAL/LokaliseText';


const FlavorIcon = (props) => {

  const {tag: Tag, ...iconProps } = props;

  const [iconNotFound, setIsNotFound] = useState(false);
  const [icon, setIcon] = useState();

  useEffect(() => {
    if(props.icon.id){
      setIcon(Object.values(paths).find((optIcon) => {
        return (optIcon.id === Number(iconProps.icon.id));
      }));
    }
    if(iconProps.icon.name){
      setIcon(Object.values(paths).find((optIcon) => {
        return (optIcon.name === String(iconProps.icon.name).toLowerCase());
      }));
    }
  }, [iconProps.icon]);

  const { ...svgProps } = props;

  const DynamicLoader = (params) => {
    const LazyComponent = params.path;
    if (!LazyComponent) {
      console.error('No SVG was found that match your query.', iconProps.icon);
      setIsNotFound(true)
      return null
    }

    const nuIconProps = {...iconProps.icon, ...params};

    return (
      <Suspense fallback={ <g /> }>
        <LazyComponent { ...nuIconProps }/>
      </Suspense>
    )
  }

  if (iconNotFound) {
    return null
  }

  return (
    <>
    {icon && (
    <Tag className={ clsx(iconProps.className) }>
      <svg
        viewBox={`0 0 ${icon.viewBox}`}
        className={ clsx(iconProps.icon.className) }
        shapeRendering="geometricPrecision" 
      >
        <DynamicLoader {...icon} />
  	  </svg>
      {iconProps.showText && <LokaliseText tag={'span'} slug={`flavor.${icon.lokalise_slug}`} titleize />}
    </Tag>
    )}
    </>
  );

};


FlavorIcon.propTypes = {
  icon: PropTypes.shape(),
  showText: PropTypes.bool,
   tag: PropTypes.string,
}

export default React.memo(FlavorIcon)
