import { lazy } from 'react'

export default {
'foodpairing_shellfish':{
	id:1,	
	name: 'shellfish',
	lokalise_slug:'shellfish',
	path: lazy(() => import('./shellfish')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/shellfish.svg',
},
'foodpairing_fish':{
	id:2,	
	name: 'fish',
	lokalise_slug:'fish',
	path: lazy(() => import('./fish')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/fish.svg',
},
'foodpairing_sushi':{
	id:3,	
	name: 'sushi',
	lokalise_slug:'sushi',
	path: lazy(() => import('./sushi')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/sushi.svg',
},
'foodpairing_salads':{
	id:4,	
	name: 'salads',
	lokalise_slug:'salads',
	path: lazy(() => import('./salads')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/salads.svg',
},
'foodpairing_vegetarian':{
	id:5,	
	name: 'vegetarian',
	lokalise_slug:'vegetarian',
	path: lazy(() => import('./vegetarian')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/vegetarian.svg',
},
'foodpairing_rice_based_dishes':{
	id:6,	
	name: 'rice_based_dishes',
	lokalise_slug:'rice_based_dishes',
	path: lazy(() => import('./rice_based_dishes')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/rice_based_dishes.svg',
},
'foodpairing_mushrooms':{
	id:7,	
	name: 'mushrooms',
	lokalise_slug:'mushrooms',
	path: lazy(() => import('./mushrooms')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/mushrooms.svg',
},
'foodpairing_eggs':{
	id:8,	
	name: 'eggs',
	lokalise_slug:'eggs',
	path: lazy(() => import('./eggs')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/eggs.svg',
},
'foodpairing_crepes':{
	id:9,	
	name: 'crepes',
	lokalise_slug:'crepes',
	path: lazy(() => import('./crepes')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/crepes.svg',
},
'foodpairing_sandwiches':{
	id:10,	
	name: 'sandwiches',
	lokalise_slug:'sandwiches',
	path: lazy(() => import('./sandwiches')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/sandwiches.svg',
},
'foodpairing_soups_stews_casseroles':{
	id:11,	
	name: 'soups | stews | casseroles',
	lokalise_slug:'soups_stews_casseroles',
	path: lazy(() => import('./soups_stews_casseroles')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/soups_stews_casseroles.svg',
},
'foodpairing_pastas':{
	id:12,	
	name: 'pastas',
	lokalise_slug:'pastas',
	path: lazy(() => import('./pastas')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/pastas.svg',
},
'foodpairing_pizzas':{
	id:13,	
	name: 'pizzas',
	lokalise_slug:'pizzas',
	path: lazy(() => import('./pizzas')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/pizzas.svg',
},
'foodpairing_french_fries':{
	id:14,	
	name: 'french fries',
	lokalise_slug:'french_fries',
	path: lazy(() => import('./french_fries')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/french_fries.svg',
},
'foodpairing_charcuterie_cheeses':{
	id:15,	
	name: 'charcuterie | cheeses',
	lokalise_slug:'charcuterie_cheeses',
	path: lazy(() => import('./charcuterie_cheeses')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/charcuterie_cheeses.svg',
},
'foodpairing_appetizers':{
	id:16,	
	name: 'appetizers',
	lokalise_slug:'appetizers',
	path: lazy(() => import('./appetizers')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/appetizers.svg',
},
'foodpairing_poultry':{
	id:17,	
	name: 'poultry',
	lokalise_slug:'poultry',
	path: lazy(() => import('./poultry')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/poultry.svg',
},
'foodpairing_pork_veal':{
	id:18,	
	name: 'pork | veal',
	lokalise_slug:'pork_veal',
	path: lazy(() => import('./pork_veal')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/pork_veal.svg',
},
'foodpairing_burgers':{
	id:19,	
	name: 'burgers',
	lokalise_slug:'burgers',
	path: lazy(() => import('./burgers')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/burgers.svg',
},
'foodpairing_lamb':{
	id:20,	
	name: 'lamb',
	lokalise_slug:'lamb',
	path: lazy(() => import('./lamb')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/lamb.svg',
},
'foodpairing_beef':{
	id:21,	
	name: 'beef',
	lokalise_slug:'beef',
	path: lazy(() => import('./beef')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/beef.svg',
},
'foodpairing_smoked_meats_bbq':{
	id:22,	
	name: 'smoked meats bbq',
	lokalise_slug:'smoked_meats_bbq',
	path: lazy(() => import('./smoked_meats_bbq')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/smoked_meats_bbq.svg',
},
'foodpairing_sausages_hot_dogs':{
	id:23,	
	name: 'sausages | hot dogs',
	lokalise_slug:'sausages_hot_dogs',
	path: lazy(() => import('./sausages_hot_dogs')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/sausages_hot_dogs.svg',
},
'foodpairing_offal':{
	id:24,	
	name: 'offal',
	lokalise_slug:'offal',
	path: lazy(() => import('./offal')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/offal.svg',
},
'foodpairing_game':{
	id:25,	
	name: 'game',
	lokalise_slug:'game',
	path: lazy(() => import('./game')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/game.svg',
},
'foodpairing_international':{
	id:26,	
	name: 'international',
	lokalise_slug:'international',
	path: lazy(() => import('./international')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/international.svg',
},
'foodpairing_desserts':{
	id:27,	
	name: 'desserts',
	lokalise_slug:'desserts',
	path: lazy(() => import('./desserts')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/desserts.svg',
},
'foodpairing_fruit':{
	id:28,	
	name: 'fruit',
	lokalise_slug:'fruit',
	path: lazy(() => import('./fruit')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/fruit.svg',
},
'foodpairing_bread':{
	id:29,	
	name: 'bread',
	lokalise_slug:'bread',
	path: lazy(() => import('./bread')),
	viewBox: '200 200',
	fallback_path: 'https://cdn.preferabli.com/icons-food-categories/bread.svg',
},
}