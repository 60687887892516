import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import DocEvents from 'UTILS/events';
import LoadingDots from 'GLOBAL/LoadingDots';
import List from 'GLOBAL/List';
import FlavorIcon from 'ICONS/FlavorIcon';
import FoodPairingIcon from 'ICONS/FoodPairingIcon';
import PreferabliLogo from 'LOGO/preferabli';
import PoweredByLogo from 'LOGO/poweredby';
import HelperTooltip from 'GLOBAL/HelperTooltip';

import { cleanTrim, titleize } from 'HELPERS/text';
import profileKeys from 'UTILS/profileKeys';
import D3Scale from 'GLOBAL/D3Scale';
import { isHTML } from 'HELPERS/validator';
import { fetchParams } from 'HELPERS/url';
import { htmlToReact } from 'HELPERS/react';
import { underscore } from 'HELPERS/text';
import BlockHeading from 'GLOBAL/BlockHeading';

import { AppProvider, AppContext } from 'COMPONENTS/AppContext';
import withContext from 'HOC/withContext';

const WrapperLoader = styled.div`
	wdith:100%;
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	display:flex;
	align-items:center;
`;

const ProductDetails = (props) => {

	const _c = useContext(AppContext);

	const [isLoading, setIsLoading] = useState(true);
	const [hasError, setError] = useState(false);
	const [productDetails, setDetails] = useState(null);

	useEffect(() => {
		const getProductProfile = async () => {
		    let { product_id, landing_url, year, types, characteristics } = props;

		    const _urlParams = (['dan.preferabli.com', 'localhost:3000'].includes(window.location.host)) ? fetchParams() : false;

		    if(characteristics){
			    if(((Object.keys(characteristics).length && characteristics.display && characteristics.display === 'scale') || characteristics === 'scale')
			    	&& types.findIndex((_t) => (_t.startsWith('characteristics'))) >= 0){
			    	const _idx = types.findIndex((_t) => (_t.startsWith('characteristics')));
			    	types.splice(_idx, 1, 'characteristics_percent');
			    }

			    if((!Object.keys(characteristics).length || !characteristics.display)
			    	&& characteristics !== 'scale' 
			    	&& types.findIndex((_t) => (_t.startsWith('characteristics'))) >= 0){
			    	const _idx = types.findIndex((_t) => (_t.startsWith('characteristics')))
			    	types.splice(_idx, 1, 'characteristics_level');
			    }
			}

		    try {

		    	let _params = {
		    		types,
		    	};

		    	if(Object.keys(_urlParams).length) {
		    		if(Object.keys(_urlParams).includes('product_id') && String(_urlParams.product_id).length){
		    			_params.product_id = _urlParams.product_id;
		    		}

		    		if(Object.keys(_urlParams).includes('landing_url') && String(_urlParams.landing_url).length){
		    			_params.landing_url = _urlParams.landing_url;
		    		}

		    		if(Object.keys(_urlParams).includes('year') && String(_urlParams.year).length){
		    			_params.year = _urlParams.year;
		    		}

		    		if(Object.keys(_urlParams).includes('merchant_variant_id') && String(_urlParams.merchant_variant_id).length){
		    			_params.merchant_variant_id = _urlParams.merchant_variant_id;
		    		}
		    	}

		    	if(!_urlParams || !Object.keys(_urlParams).length){
		    		if(product_id) _params.merchant_variant_id = product_id;
		    		if(landing_url) _params.landing_url = encodeURIComponent(landing_url);
		    	}

		    	_c.analytics.track('product profile requested', {
		    		..._params,
		    	});

		        const profile = await _c.api.getProductProfile(_params);


		        if (profile) {

		        	_c.analytics.track('product profile fetched', {
			    		..._params,
			    	});

		            setIsLoading(false);
		            setDetails(profile);
		        }
		    } catch (error) {
		    }
		}
			
		getProductProfile();


		return () => {};
	}, []);

	const generateBlockStyleObj = (params) => {
		let _style = {};

		const {
			format,
			numberRows,
			numberCols,
		} = params;

		if(format){
			switch (format){
			case 'column':
			case 'columns':
				_style['gridAutoFlow'] = 'row';
				break;
			case 'row':
			case 'rows':
				_style['gridAutoFlow'] = 'column';
				break;
			}
		}

		if(format.startsWith('column')){
			_style['gridTemplateColumns'] = `repeat(${Number(numberRows || numberCols)}, minmax(0, 1fr))`;
		}
		if(format.startsWith('rows')){
			_style['gridTemplateRows'] = `repeat(${Number(numberRows || numberCols)}, minmax(0, 1fr))`;
		}

		return _style;
	};

	return (
		<div className={clsx(
			'pdjs__block', 
			`pdjs__logo-spacer-${(props.logo_placement.startsWith('top-') ? 'top' : 'bottom')}`,
			props.devMode && 'pdjs__devMode'
		)}>

		{!isLoading && productDetails && (
			<>
			<div className={clsx('pdjs__header')}>
			{(props.customHeading?.block) && (
				<>
				{(typeof props.customHeading?.block === 'string' && !isHTML(props.customHeading?.block)) ? ( <h2>{props.customHeading?.block}</h2> ) : (<>{ htmlToReact( props.customHeading?.block )} </>)}
				</>
			)}
			{props.show_help && <HelperTooltip />}
			</div>
			<div className={clsx(
				'pdjs__content',
				(typeof props.display_format === 'string') && `pdjs__content--${props.display_format}`,
			)}
			style={ (typeof props.display_format === 'object') ? generateBlockStyleObj(props.display_format) : {} }
			>
			{(productDetails.profile && productDetails.profile.length > 0) && (
				<div className={clsx('pdjs__content-block')}>
				{props.show_block_headings && <BlockHeading 
					customHeading={ props.customHeading?.profile } 
					defaultHeading={ _c.lang.getSlugTranslation({slug: `productdetails.heading.profile`, canTitleize: true}) } 
					/> 
				} 
				<List>
					{ productDetails.profile.map((_detail, _dIdx) => {
						return (
							<List.Item key={ _dIdx }>
								<strong>{ _detail.key }</strong>: {_detail.value}
							</List.Item>
						);
					}) }
				</List>
				</div>
			)}
			{(productDetails.characteristics_level && productDetails.characteristics_level.length > 0) && (
				<div className={clsx('pdjs__content-block')}>
				{props.show_block_headings && <BlockHeading 
					customHeading={ props.customHeading?.characteristics } 
					defaultHeading={ _c.lang.getSlugTranslation({slug: `productdetails.heading.characteristics`, canTitleize: true}) } 
					/> 
				} 
				<List>
					{ productDetails.characteristics_level.map((_detail, _dIdx) => {
						return (
							<List.Item key={ _dIdx }>
								<strong>{ _detail.key }</strong>: {_detail.value}
							</List.Item>
						);
					}) }
				</List>
				</div>
			)}
			{(productDetails.characteristics_percent && productDetails.characteristics_percent.length > 0) && (
				<div className={clsx('pdjs__content-block')}>
				{props.show_block_headings && <BlockHeading 
					customHeading={ props.customHeading?.characteristics } 
					defaultHeading={ _c.lang.getSlugTranslation({slug: `productdetails.heading.characteristics`, canTitleize: true}) } 
					/> 
				} 
				<List className={clsx('pdjs__scale-list')}> 
					{ productDetails.characteristics_percent.map((_detail, _dIdx) => {
						return (
							<List.Item key={ _dIdx } className={clsx('')}>
								<span>{ _detail.key }</span>
								<D3Scale 
									id={`characteristic_scale_${underscore(_detail.key)}`} 
									defaultValue={ Number(_detail.value) } 
									{ ...props?.characteristics } 
								/>
							</List.Item>
						);
					}) }
				</List>
				</div>
			)}			
			{(productDetails.flavor && productDetails.flavor.length > 0) && (
				<div className={clsx('pdjs__content-block')}>
				{props.show_block_headings && <BlockHeading 
					customHeading={ props.customHeading?.flavor } 
					defaultHeading={ _c.lang.getSlugTranslation({slug: `productdetails.heading.flavor`, canTitleize: true}) } 
					/> 
				} 
				<ul 
					role={'list'} 
					className={clsx(
						'pdjs__grid', 
						props.flavor?.display && `pdjs__grid--${props.flavor.display}`, 
						props.flavor?.gap && `pdjs__grid-gap-${props.flavor.gap}`
					)}>

					{productDetails.flavor.map((_id) => {
						return (<FlavorIcon 
						className={clsx(
							'pdjs__grid-item', 
							props.flavor.item?.display && `pdjs__grid-item-${props.flavor.item.display}`, 
							props.flavor.item?.class
						)} 
						key={ _id } 
						icon={{ 
							id: _id, 
							className: clsx(props.flavor.icon?.class), 
							fill: props.flavor.icon?.fill,
						}} 
						tag={ 'li' }
						showText 
						/>); 
					})}
				</ul>
				</div>
			)}
			{(productDetails.foodpairing && productDetails.foodpairing.length > 0) && (
				<div className={clsx('pdjs__content-block')}>
				{props.show_block_headings && <BlockHeading 
					customHeading={ props.customHeading?.foodpairing } 
					defaultHeading={ _c.lang.getSlugTranslation({slug: `productdetails.heading.foodpairing`, canTitleize: true}) } 
					/> 
				} 
				<ul 
					role={'list'} 
					className={clsx(
						'pdjs__grid', 
						props.foodpairing?.display && `pdjs__grid--${props.foodpairing.display}`, 
						props.foodpairing?.gap && `pdjs__grid-gap-${props.foodpairing.gap}`
					)}>

					{productDetails.foodpairing.map((_id) => {
						return (<FoodPairingIcon 
						className={clsx(
							'pdjs__grid-item', 
							props.foodpairing.item?.display && `pdjs__grid-item-${props.foodpairing.item.display}`, 
							props.foodpairing.item?.class
						)} 
						key={ _id } 
						icon={{ 
							id: _id, 
							className: clsx(props.foodpairing.icon?.class), 
							fill: props.foodpairing.icon?.fill,
						}} 
						tag={ 'li' }
						showText 
						/>); 
					})}
				</ul>
				</div>
			)}
			</div>

			<div className={clsx('pdjs__logo',
				`pdjs__logo--${props.logo_placement}`, 
				props?.logo_class && props?.logo_class.length > 0 && props.logo_class 
			)} 
			style={props.logo_inline_style}>
				{props?.logo_before && parse(props.logo_before)}
				{!props.use_preferabli_logo && (<PoweredByLogo logoNumber={props.logo_option} />)}
				{props.use_preferabli_logo && (<PreferabliLogo logoNumber={props.logo_option} />)}
				{props?.logo_after && parse(props.logo_after)}
			</div>

			</>
		)}
		{isLoading && !productDetails && (
			<WrapperLoader>
				<LoadingDots className={ clsx('fade', isLoading && 'in') } showText={ false } />
			</WrapperLoader>
		)}

		</div>
	);

}

export default withContext(AppProvider, ProductDetails);
