export default {
    wine: {
        profile: [
            'type',
            'region',
            'primary_grape',
        ],
        characteristics: [
            'sweetness',
            'body',
            'acidity',
            'oak',
        ],
        characteristics_level: [
            'sweetness_level',
            'body_level',
            'acidity_level',
            'oak_level',
        ],
        characteristics_percent: [
            'sweetness_percent',
            'body_percent',
            'acidity_percent',
            'oak_percent',
        ],
        flavor: [
            'flavor_profile_1_id',
            'flavor_profile_2_id',
            'flavor_profile_3_id',
            'flavor_profile_4_id',
        ],
        foodpairing: [
            'food_category_1_id',
            'food_category_2_id',
            'food_category_3_id',
            'food_category_4_id',
        ],
    },
    whiskey: {
        profile: [
            'type',
            'region',
            'primary_grape',
        ],
        characteristics: [
            'sweetness',
            'body',
            'oak',
            'peat',
        ],
        characteristics_level: [
            'sweetness_level',
            'body_level',
            'oak_level',
            'peat_level',
        ],
        characteristics_percent: [
            'sweetness_percent',
            'body_percent',
            'oak_percent',
            'peat_percent',
        ],
        flavor: [
            'flavor_profile_1_id',
            'flavor_profile_2_id',
            'flavor_profile_3_id',
            'flavor_profile_4_id',
        ],
        foodpairing: [
            'food_category_1_id',
            'food_category_2_id',
            'food_category_3_id',
            'food_category_4_id',
        ],
    },
    tequila: {
        profile: [
            'type',
            'region',
            'primary_grape',
        ],
        characteristics: [
            'sweetness',
            'agave',
            'smoke',
            'oak',
        ],
        characteristics_level: [
            'sweetness_level',
            'agave_level',
            'smoke_level',
            'oak_level',
        ],
        characteristics_percent: [
            'sweetness_percent',
            'agave_percent',
            'smoke_percent',
            'oak_percent',
        ],
        flavor: [
            'flavor_profile_1_id',
            'flavor_profile_2_id',
            'flavor_profile_3_id',
            'flavor_profile_4_id',
        ],
        foodpairing: [
            'food_category_1_id',
            'food_category_2_id',
            'food_category_3_id',
            'food_category_4_id',
        ],
    },
    beer: {
        profile: [
            'type',
            'region',
            'primary_grape',
        ],
        characteristics: [
            'hop',
            'malt',
            'body',
            'sweetness',
        ],
        characteristics_level: [
            'hop_level',
            'malt_level',
            'body_level',
            'sweetness_level',
        ],
        characteristics_percent: [
            'hop_percent',
            'malt_percent',
            'body_percent',
            'sweetness_percent',
        ],
        flavor: [
            'flavor_profile_1_id',
            'flavor_profile_2_id',
            'flavor_profile_3_id',
            'flavor_profile_4_id',
        ],
        foodpairing: [
            'food_category_1_id',
            'food_category_2_id',
            'food_category_3_id',
            'food_category_4_id',
        ],
    },
    rtd: {
        profile: [
            'type',
            'region',
            'primary_grape',
        ],
        characteristics: [
            "sweetness",
            "flavor",
            "carbonation",
            "alcohol",
        ],
        characteristics_level: [
            "sweetness_level",
            "flavor_level",
            "carbonation_level",
            "alcohol_level",
        ],
        characteristics_percent: [
            "sweetness_percent",
            "flavor_percent",
            "carbonation_percent",
            "alcohol_percent"
        ],
        flavor: [
            'flavor_profile_1_id',
            'flavor_profile_2_id',
            'flavor_profile_3_id',
            'flavor_profile_4_id',
        ],
        foodpairing: [
            'food_category_1_id',
            'food_category_2_id',
            'food_category_3_id',
            'food_category_4_id',
        ],
    },
    cheese: {
        profile: [
            'type',
            'region',
            'primary_grape',
        ],
        characteristics: [
            "aromatic",
            "flavor",
            "savouriness",
            "firmness",
        ],
        characteristics_level: [
            "aromatic_level",
            "flavor_level",
            "savouriness_level",
            "firmness_level",
        ],
        characteristics_percent: [
            "aromatic_percent",
            "flavor_percent",
            "savouriness_percent",
            "firmness_percent"
        ],
        flavor: [
            'flavor_profile_1_id',
            'flavor_profile_2_id',
            'flavor_profile_3_id',
            'flavor_profile_4_id',
        ],
        foodpairing: [
            'food_category_1_id',
            'food_category_2_id',
            'food_category_3_id',
            'food_category_4_id',
        ],
    }
}
