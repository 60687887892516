export const getNonLatin = (text) => {
  var latinRegex = new RegExp('[\u00C0-\u00ff]+', 'ig');
  var cleanText = text.toLowerCase().trim();
  if (latinRegex.test(cleanText)) {
    let nonLatin = text.toLowerCase();
    nonLatin = nonLatin.replace(/[\u00C0-\u00ff]+/gi, (match) => {
      var char;
      switch (match) {
      case 'è':
      case 'é':
      case 'ê':
      case 'ë':
        char = 'e';
        break;
      case 'à':
      case 'á':
      case 'â':
      case 'ã':
      case 'ä':
      case 'å':
      case 'æ':
        char = 'a';
        break;
      case 'ò':
      case 'ó':
      case 'ô':
      case 'õ':
      case 'ö':
        char = 'o';
        break;
      }

      return char;
    });
    return nonLatin;
  }

  return text.toLowerCase();
};

export const slugify = (text) => {
    return text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
}

export const removeSpecialChars = (string) => string.replace(/[^\d\w\s]/g, '');

export const cleanTrim = (string) => {
    return string.toLowerCase()
        .replace(/_/g, ' ')
        .replace(/[^\d\w\s]/g, '')
        .trim();
}

export const capitalize = (s) =>
    typeof s !== 'string' ? '' : s.charAt(0).toUpperCase() + s.slice(1);

export const pluralize = (string, count) =>
    count > 1 && string.charAt(string.length - 1) !== 's' ? `${string}s` : string;

export const titleize = (string) => {
    string = string.toLowerCase().split(' ');
    for (var i = 0; i < string.length; i++) {
        string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1);
    }
    return string.join(' ');
};

export const camelCase = (string) => {
    var regex = /(?:\s|\_|\-|\.)(\w)+?/gi;
    return string.toLowerCase().replace(regex, function(match, letter) {
        return letter.toUpperCase();
    });
};

export const underscore = (string) => {
    return string.toLowerCase().replace(/\s/gi, '_');
};

export const strToArr = (string, sep) => {
    const _string = string.toLowerCase(); //force lowercase
    return _string.split(sep);
}