import React  from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';


const LoadingDots = ({
  className, 
  showText=true, 
  text=(
    <>
      Loading
      {' '}
      <span key="ellipsis">&hellip;</span>
    </>
  ), 
  numDots=3, 
  autoCenter=false, 
  color, 
  ...props
}) => {
  const setLoadingColor = () => color ? { backgroundColor: color } : {};

  const dots = [];
  for (let i = 0; i < numDots; i++) {
    dots.push(<div className="loading-dots__dot" key={ i } style={ setLoadingColor() } />);
  }

  return (
    <div className={ clsx(className, 'align-items-center', autoCenter && 'm-auto') }>
      <div className={ clsx('loading-dots', 'mb-3', props.dotColor) }>{dots}</div>
      {showText && (
        <legend className="text-center">
          {typeof text !== 'undefined' && text.length > 0 ? (
            <>
              {`${text}`}
              <span key="ellipsis">&hellip;</span>
            </>
          ) : (
            text
          )}
        </legend>
      )}
    </div>
  );
};

LoadingDots.propTypes = {
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  showText: PropTypes.bool,
  color: PropTypes.string,
  autoCenter: PropTypes.bool,
  numDots: PropTypes.number,
};

export default React.memo(LoadingDots);