import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';


const ListItem = ({children, ...props}) => (<li role="list-item">{children}</li>);
const ListHeading = ({children, ...props}) => (<li className={clsx('pdjs__list-heading')}>{children}</li>);


const List = ({children, ...props}) => {
	return (
		<ul rols="list" className={clsx('pdjs__list', props.className)}>
			{children}
		</ul>
	);
};

List.propTypes = {
	children: PropTypes.node,
	className: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
	showHeading: PropTypes.bool,
	heading: PropTypes.string,
};

export default Object.assign(List, {
	Item: ListItem,
	Heading: ListHeading,
});
