import React from 'react';

const icon = (props) => {
  return (
  <g fill={ props.fill } transform="translate(32 52.8)">
    <path d="M51.6435149,26.1530194 C59.1635149,30.4730194 59.3235149,40.5530194 55.4835149,47.4330194 C51.6435149,54.3130194 42.6835149,59.1130194 35.1635149,54.7930194 C27.6435149,50.4730194 27.4835149,40.3930194 31.3235149,33.5130194 C35.1635149,26.6330194 44.1235149,21.6730194 51.6435149,26.1530194 Z M48.9235149,30.9530194 C44.7635149,28.5530194 39.3235149,33.8330194 37.5635149,36.8730194 C35.8035149,39.9130194 33.8835149,47.2730194 38.2035149,49.6730194 C42.5235149,52.0730194 47.8035149,46.7930194 49.5635149,43.7530194 C51.1635149,40.7130194 53.0835149,33.3530194 48.9235149,30.9530194 L48.9235149,30.9530194 Z"/><path d="M128.923515,8.71301941 C109.563515,8.71301941 93.0835149,8.23301941 79.3235149,7.75301941 C29.5635149,6.31301941 19.0035149,5.99301941 9.56351486,33.9930194 C0.283514856,61.6730194 11.8035149,79.9130194 18.0435149,87.2730194 C23.4835149,81.1930194 34.5235149,71.2730194 52.1235149,66.6330194 C75.9635149,60.2330194 108.603515,56.0730194 121.243515,33.5130194 C129.883515,18.3130194 129.723515,11.4330194 128.923515,8.71301941 L128.923515,8.71301941 Z M79.0035149,0.553019407 C96.1235149,1.03301941 113.243515,1.35301941 130.363515,1.35301941 C140.123515,11.1130194 132.123515,29.1930194 126.523515,38.9530194 C112.443515,63.9130194 80.6035149,67.9130194 55.3235149,74.7930194 C33.8835149,80.3930194 25.2435149,94.3130194 25.2435149,94.3130194 L18.0435149,95.9130194 C18.0435149,95.9130194 -9.95648514,72.5530194 3.80351486,31.7530194 C15.0035149,-1.36698059 26.2035149,-1.04698059 79.0035149,0.553019407 Z"/>
  </g>
  )
};

export default icon;
