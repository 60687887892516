export const isHTML = (str) => !(str || '')
    .replace(/<([^>]+?)([^>]*?)>(.*?)<\/\1>/gi, '')
    .replace(/(<([^>]+)>)/gi, '')
    .trim();
    
export const isFullUrl = (string) => {
    /* eslint no-control-regex: "error" */
    const regex = /(?:(?:(?:[a-z]+:)?\/\/)|www.)(?:S+(?::S*)?@)?(?:localhost|(?:(?:[a-z\u00a1-\uffff0-9][-_]*)*[a-z\u00a1-\uffff0-9]+)(?:.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:.(?:[a-z\u00a1-\uffff]{2,})))(?::d{2,5})?(?:[/?#][^s"]*)?/;
    const pattern = new RegExp(regex, 'i'); // fragment locator
    return !!pattern.test(string);
};

export const validHex = (color) => {
    if(!color || typeof color !== 'string') return false;

    // Validate hex values
    if(color.substring(0, 1) === '#') color = color.substring(1);

    switch(color.length) {
      case 3: return /^[0-9A-F]{3}$/i.test(color);
      case 6: return /^[0-9A-F]{6}$/i.test(color);
      case 8: return /^[0-9A-F]{8}$/i.test(color);
      default: return false;
    }

    return false;
  }

export const validHtml = (str) => {
  const fragment = document.createRange().createContextualFragment(str);
  fragment.querySelectorAll('*').forEach(el => el.parentNode.removeChild(el));
  return !(fragment.textContent || '').trim();
}

