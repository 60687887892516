import clsx                                from 'clsx';

import PropTypes                           from 'prop-types';
import React, { Suspense, lazy, useState, useEffect } from 'react'
import paths from './foodpairing-icons';
import LokaliseText from 'GLOBAL/LokaliseText';


const FoodPairingIcon = ({
  tag: Tag = 'div',
  showText=false,
  ...props
}) => {

  const [iconNotFound, setIsNotFound] = useState(false);
  const [icon, setIcon] = useState();

  useEffect(() => {
    if(props.icon.id){
      setIcon(Object.values(paths).find((optIcon) => {
        return (optIcon.id === Number(props.icon.id));
      }));
    }
    if(props.icon.name){
      setIcon(Object.values(paths).find((optIcon) => {
        return (optIcon.name === String(props.icon.name).toLowerCase());
      }));
    }
  }, [props.icon]);

  const { ...svgProps } = props;

  const DynamicLoader = (params) => {
    const LazyComponent = params.path;
    if (!LazyComponent) {
      console.error('No SVG was found that match your query.', props.icon);
      setIsNotFound(true)
      return null
    }

    const nuIconProps = {...props.icon, ...params};

    return (
      <Suspense fallback={ <g /> }>
        <LazyComponent { ...nuIconProps }/>
      </Suspense>
    )
  }

  if (iconNotFound) {
    return null
  }

  return (
    <>
    {icon && (
    <Tag className={ clsx(props.className) }>
      <svg
        viewBox={`0 0 ${icon.viewBox}`}
        className={ clsx(props.icon.className) }
        shapeRendering="geometricPrecision" 
      >
        <DynamicLoader {...icon} />
  	  </svg>
      {showText && <LokaliseText slug={`foodpairing.${icon.lokalise_slug}`} titleize tag={'span'} />}
    </Tag>
    )}
    </>
  );

};


FoodPairingIcon.propTypes = {
  icon: PropTypes.shape(),
  showText: PropTypes.bool,
  tag: PropTypes.string,
}

export default React.memo(FoodPairingIcon)
