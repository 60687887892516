import clsx                   from 'clsx';
import PropTypes              from 'prop-types';
import React, { useContext } from 'react';
import { AppContext } from 'COMPONENTS/AppContext';

const LokaliseText = React.forwardRef(function LokaliseText(props, ref) {

  const { lang } = useContext(AppContext);

  const { children, slug, titleize, tag: Tag, ...refProps } = props;

  const LokaliseFn = lang.getSlugTranslation({slug, canTitleize: titleize});

  return (
    <>
    {Tag && (
    <Tag className={clsx(refProps.className)}>
      {LokaliseFn || children || ''}
    </Tag>
    )}
    {!Tag && LokaliseFn || children || ''}
    </>
  );
});

LokaliseText.propTypes = {
  slug: PropTypes.string.isRequired,
  children: PropTypes.node,
  titleize: PropTypes.bool,
};

export default React.memo(LokaliseText);