import React from 'react';

const icon = (props) => {
  return (
  <g fill={ props.fill } transform="translate(32 40)">
  <path fill-rule="evenodd" d="M0.16,58.0923296 L69.92,29.9323296 L69.92,29.4523296 C69.92,13.1323296 85.28,0.0123295762 93.92,0.0123295762 C102.56,-0.467670424 117.92,13.1323296 117.92,29.4523296 C117.92,32.1723296 117.92,34.4123296 117.44,36.1723296 L136,57.9323296 L136,119.532641 L0,119.532641 C0.16,119.69233 0.16,58.0923296 0.16,58.0923296 Z M100.32,27.6923296 C102.56,27.6923296 104.48,29.4523296 104.48,31.6923296 C104.48,33.9323296 102.72,35.3723296 100.32,35.3723296 C97.92,35.3723296 96.16,34.0923296 96.16,31.6923296 C96.16,29.2923296 98.08,27.6923296 100.32,27.6923296 Z M85.76,27.6923296 C88,27.6923296 89.76,29.4523296 89.76,31.6923296 C89.76,33.9323296 88,35.3723296 85.76,35.3723296 C83.52,35.3723296 81.76,34.0923296 81.76,31.6923296 C81.76,29.2923296 83.52,27.6923296 85.76,27.6923296 Z M93.6,15.0523296 C95.84,15.0523296 97.28,16.8123296 97.28,19.0523296 C97.28,21.2923296 95.84,23.0523296 93.6,23.0523296 C91.36,23.0523296 89.6,21.2923296 89.6,19.0523296 C89.6,16.8123296 91.36,15.0523296 93.6,15.0523296 Z M94.08,6.41232958 C88.64,6.41232958 76.48,17.7723296 76.48,29.6123296 C76.48,51.3723296 91.52,42.7323296 94.08,42.7323296 C96.64,42.7323296 111.84,51.8523296 111.84,29.6123296 C111.68,17.7723296 99.52,6.41232958 94.08,6.41232958 Z M115.36,43.5323296 C111.68,50.2523296 104.48,52.6523296 94.08,49.4523296 C80,53.4523296 72.8,48.1723296 70.56,36.8123296 L15.52,58.8923296 L128,58.8923296 L115.36,43.5323296 L115.36,43.5323296 Z M6.56,92.9723296 L129.44,92.9723296 L129.44,83.5323296 L6.56,83.5323296 L6.56,92.9723296 Z M129.28,98.8923296 L6.56,98.8923296 L6.56,113.45233 L129.44,113.45233 L129.44,98.8923296 L129.28,98.8923296 Z M129.28,64.8123296 L6.56,64.8123296 L6.56,77.1323296 L129.44,77.1323296 L129.44,64.8123296 L129.28,64.8123296 Z" />
  </g>
  )
};

export default icon;
