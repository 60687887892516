import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { isHTML } from 'HELPERS/validator';
import { htmlToReact } from 'HELPERS/react';
import LokaliseLanguage from 'UTILS/LokaliseLanguage';

const BlockHeading = (props) => {
	const {
		customHeading=null,
		defaultHeading,
	} = props;

	return (
		<>
		{(customHeading) ? (
			<>
			{(typeof customHeading === 'string' && !isHTML(customHeading)) ? (<h6 role={'heading'}>{customHeading}</h6>) : (<>{ htmlToReact(customHeading)} </>)}
			</>
		) : (
			<h6 role={'heading'}>{ defaultHeading }</h6>
		)}
		</>
	);
};

BlockHeading.propTypes = {};

export default React.memo(BlockHeading);
