import React    from 'react';
import ReactDOMClient from 'react-dom/client';

import PDApp from './profile.app';

import Context from 'UTILS/Context';
import Session from 'UTILS/Session';
import LokaliseLanguage from 'UTILS/LokaliseLanguage';
import Emitter from 'UTILS/Emitter';
import DocEvents from 'UTILS/events';

import genereateStyleObj from 'UTILS/genereateStyleObj';
import { validHtml } from 'HELPERS/validator';
import { fetchParams } from 'HELPERS/url';

import Rollbar from 'UTILS/Vendor/Rollbar';
import PreferabliAnalytics from 'UTILS/Vendor/analytics';
import IntegrationsApi from 'API/IntegrationsApi';

class PreferabliPD {
	constructor(element, options) {

		var _defaults = {
			devMode: false,
			use_preferabli_logo: false,
			logo_placement: 'bottom-left',
			logo_inline_style: null,
			logo_before: null,
			logo_after: null,
			logo_option: 9,
			logo_class: '',
			lang: 'en',
			show_help: false,
            informal: false,
            display_format: 'column',
			characteristics: {
				display: null,
			},
			flavor:{
				display:'grid',
				item:{
					display: 'column',
				},
				icon: null,
			},
			foodpairing:{
				display:'grid',
				item:{
					display: 'column',
				},
				icon: null,
			},
            show_block_headings: true,
			customHeading: null,
			opt_out_tracking: false,
		}


		if(options.logo_inline_style !== null && String(options.logo_inline_style).length){
			options.logo_inline_style = genereateStyleObj(options.logo_inline_style);
		} else {
			options.logo_inline_style = {};
		}

		if(Object.keys(options).includes('logo_before')  && options.logo_before !== null && String(options.logo_before).length){
			if(!validHtml(options.logo_before)) throw 'Option:logo_before, requires to be a valid html string.';
		}

		if(Object.keys(options).includes('logo_after') && options.logo_after !== null && String(options.logo_after).length){
			if(!validHtml(options.logo_after)) throw 'Option:logo_after, requires to be a valid html string.';
		} 

		if(Object.keys(options).includes('blockDisplay')){
			options.display_format = options.blockDisplay;
			delete options.blockDisplay;
		}

		if(Object.keys(options).includes('helpTooltip')){
			options.show_help = options.helpTooltip;
			delete options.helpTooltip;
		}

		if(Object.keys(options).includes('use_preferabli_logo') && options.use_preferabli_logo){
			if(!Object.keys(options).includes('logo_option')) options.logo_option = 4;
		}

		this.opts = Object.assign(_defaults, {element}, {...options});

		if(window.Rollbar) window.Rollbar.configure({enable: true});

		this._lang = new LokaliseLanguage();

		this._api = new IntegrationsApi();
		this._api.setup({
			integration_id: this.opts.integration_id,
			opts: this.opts,
			lang: this._lang,
		});

		this._analytics = new PreferabliAnalytics();
		this._analytics.setup({
			product_id: this.opts.product_id,
			integration_id: this.opts.integration_id,
			opt_out_tracking: this.opts.opt_out_tracking
		});

		this._context = new Context();
		this._context.setOptions(this.opts);
		this._context.setIntegrationId(this.opts.integration_id);
		this._context.setProductId(this.opts.product_id);
		this._context.setLanguage(this.opts.lang);
	}

	createAttchElement = (attachElem, newElement) => {
		var elementDiv = document.createElement('div');
		elementDiv.id = newElement.replace('#', '');

		if (typeof attachElem === 'string' || !(attachElem instanceof Element)) {
			if (document.querySelector(attachElem).nodeName === 'BODY') {
				const bodyEl = document.querySelector(attachElem);
				const firstScript = Object.values(document.querySelector('body').childNodes).find(
					(htmlTag) => htmlTag.nodeName === 'SCRIPT'
				);

				document.querySelector(attachElem).insertBefore(elementDiv, firstScript);
			}
			if (document.querySelector(attachElem)) {
				document.querySelector(attachElem).appendChild(elementDiv);
			}
		}
		if (attachElem instanceof Element) {
			attachElem.appendChild(elementDiv);
			resolve();
		}
		return elementDiv;
	}

	displayDetails = (params = {}) => {
		this.render({...this.opts, ...params});
	}

	render = async (params) => {

		let _root;
		let _lang = this.opts.lang;

		const _urlParams = (['dan.preferabli.com', 'localhost:3000'].includes(window.location.host)) ? fetchParams() : false;
		if(Object.keys(_urlParams).includes('lang') && String(_urlParams.lang).length){
			_lang = _urlParams.lang;
			this._context.setLanguage(_lang)
		} 

		await this._lang.load(_lang);  // load before rendering app

		const { element, root, ...renderParams } = params;

		let node = root;
		if(!root){
			if(element instanceof jQuery && element instanceof Object) node = element[0]; // jQuery El
			if(element instanceof Element && element.nodeType === 1) node = element; // VJs El
		}

		const rootNode = (node && node instanceof Element) ? node : !document.querySelector('#profiledetails_placeholder')
				? this.createAttchElement('body', 'profiledetails_placeholder')
				: document.querySelector('#profiledetails_placeholder');


		const _props = {
			...renderParams, 
			context: this._context, 
			api: this._api, 
			analytics: this._analytics,
			lang: this._lang,
		};

		_root = ReactDOMClient.createRoot(rootNode);

		_root.render(<PDApp { ..._props } />);
	}
}

//window.preferabliPD = PreferabliPD;

export default PreferabliPD;