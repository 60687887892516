import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MaterialIcon from './MaterialIcon';
import { usePopper } from 'react-popper';
import useOutsideClick from 'HOOKS/useOutsideClick';

import { AppContext } from 'COMPONENTS/AppContext';


const HelperTooltip = (props) => {

	const _c = useContext(AppContext);

	const [expanded, setExpanded] = useState(false);
	const [clearRef, setClearRef] = useState(null);
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
	placement: 'top',
	modifiers: [],
	});

	useOutsideClick(popperElement, [popperElement], (event) => {
	    if (referenceElement.contains(event.target)) return;
	    setExpanded(false);
	  });
	
	return (
		<>
			<a 
			className={clsx('pdjs__helper')}
			role="button"
			onMouseOver={ (e) => {
				setExpanded(true);
			} }
			onMouseLeave={ (e) => {
				e.persist();
				setExpanded(false);
			} }
			ref={ setReferenceElement }
			>
				<MaterialIcon icon="help" size="20" />
			</a>
		
	        <div
	          ref={ setPopperElement }
	          style={{
	            ...styles.popper,
	            zIndex: 4,
	            minWidth: '250px',
	          }}
	          { ...attributes.popper }
	          className={clsx('pdjs__tooltip', expanded && 'in')}
	        >	
	        	<div className={clsx('pdjs__tooltip-inner')}>
	        	<p>{`${_c.lang.getSlugTranslation({slug: 'tooltip.help', canTitleize: true})}`}</p>

	        	</div>
	        </div>
		</>
	);
};

HelperTooltip.propTypes = {};

export default HelperTooltip;
