import React, { useEffect, useRef, useState } from 'react';
import { scaleLinear, select } from 'd3';

const D3Scale = (props) => {

    const { 
      dragHandler, 
      min = 0, 
      max = 100, 
      defaultValue, 
      bgScale = '#fafafa', 
      bgFill = '#000',
      height = 10,
      width = '100%',
      borderRadius = 10,
      strokeWidth = 2,
      strokeColor = '#fafafa',
    } = props;

    const svgRef = useRef(null);
    const trackHeight = parseFloat(height);
    const svgH = parseFloat(height);

    const marginLeft = 20;
    const marginRight = 20;
   
    const [state, setState] = useState({
        currentValue: +defaultValue || +min
    });
    const { currentValue } = state;
   
    const createTracks = (svg) => {
        const gTrack = svg.select('g.track');
        // east track first
        gTrack
            .append('rect')
            .attr('class', 'scale-bg')
            .attr('x', 0)
            .attr('y', +svgH / 2 - trackHeight / 2)
            .attr('rx', (borderRadius >= 0) ? borderRadius : (svgH / 2))
            .attr('ry', (borderRadius >= 0) ? borderRadius : (svgH / 2))
            .attr('height', svgH)
            .attr('width', getParentElementWidth() - 20)
            .attr('fill', bgScale)
            .attr('stroke-width', strokeWidth)
            .attr('stroke', strokeColor)
            .lower()
            .style('opacity', 0);

        // west track
        gTrack
            .append('rect')
            .attr('class', 'scale-fill')
            .attr('x', 0)
            .attr('y', +svgH / 2 - trackHeight / 2)
            .attr('rx', (borderRadius >= 0) ? borderRadius : (svgH / 2))
            .attr('ry', (borderRadius >= 0) ? borderRadius : (svgH / 2))
            .attr('height', svgH)
            .attr('width', getParentElementWidth() - 20)
            .attr('fill', bgFill)
            .style('opacity', 0);
       
    };
   
    const getParentElementWidth = () => {
        return svgRef.current.getBoundingClientRect().width;
    };

    const getXScale = () => {
        return scaleLinear()
            .domain([+min, +max])
            .range([0, getParentElementWidth() - 20]);
    };

    const initialRender = (svg) => {
        const xScale = getXScale();
        svg.select('rect.scale-fill').attr('width', xScale(currentValue)).style('opacity', 1);
        svg.select('rect.scale-bg').style('opacity', 1);
    };

    const redraw = (svg) => {
        const xScale = getXScale();
        svg.select('rect.scale-full').attr('width', xScale(currentValue));
        svg.select('rect.scale-bg').attr('width', xScale(max));
    };

    useEffect(() => {
        const svg = select(svgRef.current);
        createTracks(svg);
        initialRender(svg);

        window.addEventListener('resize', createTracks(svg));

        return () => {
          window.removeEventListener('resize', () => {});
        }
    }, []);
   
    useEffect(() => {
        const svg = select(svgRef.current);
        svg.select('rect.scale-bg').style('fill', bgScale);
        svg.select('rect.scale-fill').style('fill', bgFill);
    }, [bgScale, bgFill]);
    

  return (
      <svg
        ref={svgRef}
        id={props.id}
        version='1.1'
        baseProfile='full'
        width={ width }
        height={ height }
        xmlns='http://www.w3.org/2000/svg'
        preserveAspectRatio='xMinYMin meet'
      >
        <g className='track' />
      </svg>
  )
}

export default D3Scale