import * as React from 'react';
import parse, { attributesToProps, domToReact } from 'html-react-parser';

const hasChildOfType = (children, type) => {
    return React.Children.toArray(children).some((child) => {
        return React.isValidElement(child) && child.type === type;
    });
}

const htmlToReact = (html) => {
    const options = {
      replace: ({attribs, children, name, ...domNodeProps}) => {

        if(!domNodeProps.parent && children && children.length){
          const Tag = name;
          const props = attributesToProps(attribs);
          return (<Tag {...props}> {domToReact(children, options)} </Tag>);
        }
        if (attribs && name === 'input') {
          const props = attributesToProps(attribs);
          return (<input { ...props } onChange={ () => {} } />);
        }
        if (attribs && name === 'select') {
          const props = attributesToProps(attribs);
          return (<select { ...props } onChange={ () => {} } />);
        }
        
      },
    };

    return parse(html, { trim: true, ...options });
};


export {
    hasChildOfType, 
    htmlToReact
};