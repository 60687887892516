import Api             from './Api';
import profileKeys from 'UTILS/profilekeys';
import { getNonLatin } from 'HELPERS/text';

export default class Integrations extends Api {

  constructor(){
    super();
    this._opts = null;
    this._lang = null;
  }

  setup = (params) => {
    this.client.defaults.baseURL = `${this.client.defaults.baseURL}/${params.integration_id}/`;
    this.client.defaults.headers.client_interface = `stp_${params.integration_id}`;
    this.client.defaults.headers.client_interface_version = `${__VERSION__}`;
    this._opts = params.opts;
    this._lang = params.lang;
  }

 getProductProfile = async (params) => {
    let {
      types,
      ...query
     } = params;

     // pull category for pulling characteristics
     let _cat = await this.get('variant-details', {
      keys: ['category'],
      ...query
     }).then((_data) => (_data[0].value)).catch(() => (null));

     if(!_cat) _cat = 'wine';
     
     let keys = types.reduce((arr, type) => {
        type = String(type).toLowerCase();
        arr = arr.concat(...new Set([...arr, profileKeys[_cat][type]]));
        return arr;
      }, []);

    keys = [...new Set(keys)]; // clean arr

    try{

     const _res = await this.get(`variant-details`, {
        keys,
        ...query,
      });

     if(_res){

       /// sort keys
        const _sorted = types.reduce((o, _type) => {
          const _tKeys = profileKeys[_cat][_type];
          return Object.assign(o, {[_type]: _res.filter((_d) => (_tKeys.includes(_d.key)))});
        }, {});

        return Object.entries(_sorted).reduce((o, entry) => {
          let [_type, _arr] = entry;
          switch (_type){
            case 'profile':
               _arr = _arr.map((_d) => {
                let _slug =  `profile.${_d.key}`;
                if(_d.key === 'primary_grape') _slug = `profile.${_cat}.primary_grape`;

                let value = _d.value;
                if(_d.key === 'type' && _cat === 'wine') value = this._lang.getSlugTranslation({slug: `profile.type.${getNonLatin(_d.value)}`, canTitleize: true });

                return {key: this._lang.getSlugTranslation({slug: _slug, canTitleize: true}), value};
              });
              _arr.push({ key: this._lang.getSlugTranslation({slug: `profile.category`, canTitleize: true}) , value: this._lang.getSlugTranslation({slug: `profile.${_cat}.category`, canTitleize: true})});
              
              break;
            case 'foodpairing':
            case 'flavor':
              _arr = _arr.map((_d) => (_d.value))
              break;
            case 'characteristics_level':
            case 'characteristics_percent':
              _arr = _arr.map((_d) => {
                let _tempKey = _d.key;
                let _tempValue = _d.value;

                if(_type === 'characteristics_percent' || _type === 'characteristics_level'){
                  const _re = new RegExp(`\_(level|percent)`, 'gi');
                  _tempKey = this._lang.getSlugTranslation({slug: `characteristic.trait.${_d.key.replace(_re, '')}`, canTitleize: true});
                }

                if(_type === 'characteristics_level') {
                  _tempValue =  this._lang.getSlugTranslation({slug: `characteristics.${_cat}.${_d.key.replace('_level', '')}_${_d.value}${this._opts.informal ? '_informal' : ''}`, canTitleize: true});
                }

                return {
                  key: _tempKey,
                  value: _tempValue
                };
              });

              break;
              default:
                _arr = [];
          }

          return Object.assign(o, {[_type]: _arr});
        },{});
      }



    } catch(err){

      console.log('err', err);

      return null;
    }


  }
}

