import PreferabliPDClass from './profile.class';
import { validHex } from 'HELPERS/validator';
import { strToArr } from 'HELPERS/text';

import './scss/style.scss';

"use strict";
(function ($) {

    var _pd,
        _isJQ = !!(window.jQuery),
        _window = $(window),
        _document = $(document);

    function PreferabliPD(element, fn, options) {

        this._defaultOptions = {
			devMode: false,
			use_preferabli_logo: false,
			logo_placement: 'bottom-left',
			logo_inline_style: null,
			logo_before: null,
			logo_after: null,
			logo_option: 9,
			logo_class: '',
			lang: 'en',
			show_help: false,
			display_format: 'column',
            informal: false,
			characteristics: {
				display: null,
			},
			flavor:{
				display:'grid',
				item:{
					display: 'column',
				},
				icon: null,
			},
			foodpairing:{
				display:'grid',
				item:{
					display: 'column',
				},
				icon: null,
			},
            show_block_headings: true,
			customHeading: null,
		};

        $.extend({}, this);
        this._name = 'preferabliPD';
        this.element = element;
        this._pd = null;

        if (!((element instanceof jQuery && element instanceof Object) || (element instanceof Element) || (typeof element === 'string' && document.querySelector(element)))) {
			throw 'You must have an element to enable Preferabli Product Details plugin.';
		}

		if (typeof options.integration_id === 'undefined') {
			throw 'Intergration ID is required to access Preferabli Product Details functionality. Please contact Preferabli to obtain your integration id.';
		}

		// if (typeof options.product_id === 'undefined') {
		// 	throw 'Product ID is required for Preferabli Product Details functionality.';
		// }

		// if fn is show 
		// if options doesnt have types append types with all
		// if options.types does exist and is array of 1 with subset(0) === 'all', change types to all
		if(fn === 'show' && (!Object.keys(options).includes('types') || (Array.isArray(options.types) && options.types.length === 1 && options.types[0] === 'all'))) {
			options.types = ['profile', 'characteristics_level', 'flavor', 'foodpairing'];
		}

		if(!options.show_block_headings 
			&& options.customHeadings 
			&& Object.keys(options.customHeadings).length){
			options.show_block_headings = true;
		}

		/// settings check
		if(options.characteristics){
			if(options.characteristics.display === 'scale'){ 
				if(Object.keys(options.characteristics).includes('bgFill') && options.characteristics.bgFill){
					if(!validHex(options.characteristics.bgFill)){
						throw `Enter a valid hex value for characteristics scale bgFill, ie: #fff, #ffffff`;
					}
				}
			}
		}
		if(options.flavor && options.flavor.icon && Object.keys(options.flavor.icon).includes('fill')){
			if(!validHex(options.flavor.icon.fill)){
				throw `Enter a valid hex value for flavor icon fill, ie: #fff, #ffffff`;
			}
		}

		if(Object.keys(options).includes('use_preferabli_logo') && options.use_preferabli_logo){
			if(!Object.keys(options).includes('logo_option')) options.logo_option = 4;
		}

        this.opts = $.extend({}, this._defaultOptions, Object.assign(options, { element }));

        this.show = this.show.bind(this, {...this.opts}, {types: this.opts.types});
		this.all = this.show.bind(this, {...this.opts}, {types: ['profile', 'characteristics_level', 'flavor', 'foodpairing']});
		this.profile = this.show.bind(this, {...this.opts}, {types: ['profile']});
		this.characteristics = this.show.bind(this, {...this.opts}, {types: ['characteristics_level']});
		this.flavor = this.show.bind(this, {...this.opts}, {types: ['flavor']});
		this.foodpairing = this.show.bind(this, {...this.opts}, {types: ['foodpairing']});

        this.init();
        if(fn){
			this[fn](this.opts);
		}

		return this;
    };


    PreferabliPD.prototype = {
        constructor: PreferabliPD,
        init: function() {
        	let _el;
			if(this.opts.element instanceof jQuery && this.opts.element instanceof Object) _el = this.opts.element[0]; // jQuery El
			if(this.opts.element instanceof Element && this.opts.element.nodeType === 1) _el = this.opts.element; // VJs El
			this.opts.root = _el; // root for react
            this._pd = new PreferabliPDClass(_el, {...this.opts});
        },
        show: function(params = {}, types) {
            if(!this.opts.types) this.opts.types = {...types};
        	this._pd.render({ ...params, ...types });
        }	
    };

    $.fn.preferabliPD = function(options) {

    	var fn = typeof arguments[0] === 'string' ? arguments[0] : 'show';
		var args = arguments.length > 1 ? Array.prototype.slice.call(arguments, 1)[0] : arguments[0];

        if (typeof options === "string" || (['array','object'].includes(typeof options) && Array.isArray(options))) {
            return this.each(() => {

            	let _types = [];
                var jqEl = $(this);
                var instance = jqEl.data('preferabliPD');

            	if((typeof options === 'string' && options.search(/[\|\,]+/gi)) || (['array','object'].includes(typeof options) && Array.isArray(options))) fn = 'show'; // force to show
                if(typeof options === 'string' && options.search(/[\|\,]+/gi)){
                	_types = strToArr(options, /[\|\,]+/gi);
                }
                if(['array','object'].includes(typeof options) && Array.isArray(options)) _types = [...options];

            	args = {...args, types: _types};
                return (instance && instance[options]) 
                	? instance[options].apply(instance, Array.prototype.slice.call(arguments, 1)) 
                		: jqEl.data('preferabliPD', new PreferabliPD(this, fn, args));
            });

        } else {
            return this.each(() => {

                var jqEl = $(this);
                options = $.extend(true, {}, options);

                if (_isJQ) {
                    jqEl.data('preferabliPD', new PreferabliPD(this, fn, args));
                } else {
                    jqEl[0].preferabliPD = new PreferabliPD(this, fn, args);
                }

                return this;
            });
        }
    };

    $.fn.preferabliProductDetails = $.fn.preferabliPD;
    $.fn.preferabliDetails = $.fn.preferabliPD;
    $.fn.preferabliProfile = $.fn.preferabliPD;

})(window.jQuery);